import {deleteToken, getMessaging, getToken} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import firebaseConfig from "../../constants/firebase-config";
import {useCallback, useMemo, useState} from "react";
import useAsyncEffect from "../utils/useAsyncEffect";
import {useCurrentDevice} from "../filter/device.filter";
import {useDeviceActions} from "../actions/device.actions";

interface IPushNotificationResult {
  issues: string[];
  isTokenConfigured: boolean;
  requestEnablePush: () => Promise<boolean>;
  requestDisablePush: () => Promise<boolean>;
}

const vapidKey = process.env.FB_VAPID_KEY || '';

export const app = initializeApp(firebaseConfig);
const getFcmToken = async (): Promise<string> => {

  const registrations = await navigator.serviceWorker.getRegistrations();

  if (registrations.length === 0)
    console.warn('Cannot get push-token: no service-worker registration found');

  const messaging = getMessaging(app);

  const token = await getToken(messaging, {
    serviceWorkerRegistration: registrations[0],
    vapidKey,
  });

  console.debug('push-token received', token);

  return token;
};

const deleteFcmToken = async (): Promise<boolean> => {
  const messaging = getMessaging(app);
  return await deleteToken(messaging);
};

const usePushNotification = (): IPushNotificationResult => {

  const currentDevice = useCurrentDevice();
  const deviceActions = useDeviceActions();

  const [notificationPermission, setNotificationPermission] = useState<NotificationPermission>("default");

  const issues = useMemo<string[]>(() => {
    const issuesList: string[] = [];

    if (notificationPermission !== 'granted')
      issuesList.push('Dein Browser hat die Verwendung von Push-Nachrichten abgelehnt'); //todo: translate

    if (currentDevice === null)
      issuesList.push('Deine Geräteeinstellungen konnten nicht mit dem Server synchronisiert werden'); // todo: translate

    return issuesList;
  }, [notificationPermission, currentDevice]);

  const isTokenConfigured = useMemo(() => {
    return currentDevice?.pushToken !== null;
  }, [currentDevice]);

  const requestNotificationPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();
    console.debug('notification permission requested...', permission);
    setNotificationPermission(permission);
    return permission;
  }, [setNotificationPermission]);

  const requestEnablePush = useCallback(async () => {

    if (notificationPermission !== 'granted') {
      const permissionResult = await requestNotificationPermission();
      if (permissionResult !== 'granted')
        return false;
    }

    if (currentDevice === null)
      return false;

    try {
      const fcmToken = await getFcmToken();

      await deviceActions.updateItem({
        ...currentDevice,
        pushToken: fcmToken,
      });
    }
    catch (error) {
      console.error('failed to obtain fcm token', error);
      return false;
    }

    return true;
  }, [currentDevice, deviceActions, notificationPermission, requestNotificationPermission]);

  const requestDisablePush = useCallback(async () => {

    if (currentDevice !== null) {
      await deviceActions.updateItem({
        ...currentDevice,
        pushToken: null,
      });
    }

    try {
      return await deleteFcmToken();
    }
    catch (error) {
      console.error('failed to delete fcm token', error);
      return false;
    }
  }, [currentDevice, deviceActions]);

  useAsyncEffect(async () => {
    setNotificationPermission(Notification.permission);
  }, []);

  return {
    issues,
    isTokenConfigured,
    requestEnablePush,
    requestDisablePush
  }
};

export default usePushNotification;
