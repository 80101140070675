import React, {FC, useCallback, useState} from "react";
import BasePageWrapper from "../../base-page-wrapper/BasePageWrapper";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import {ColorMode, useColorModeAppSettings} from "../../../hooks/utils/useAppSettings";
import {useTranslation} from "react-i18next";
import {Section} from "../../../components/section/Section";
import {useAppNotifications} from "../../../hooks/utils/useAppNotifications";
import usePushNotification from "../../../hooks/native/usePushNotification";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ColorModeSettings: FC = () => {

  const { t } = useTranslation();
  const [colorMode, setColorMode] = useColorModeAppSettings();

  const getColorModeButton = (mode: ColorMode) => {
    return (
      <Button
        variant={colorMode === mode ? 'contained' : 'outlined'}
        onClick={() => setColorMode(mode)}
      >
        {t(`appSettingsPage.colorMode.${mode}`)}
      </Button>
    );
  }

  return (
    <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Typography>Design</Typography>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        {getColorModeButton(ColorMode.Light)}
        {getColorModeButton(ColorMode.System)}
        {getColorModeButton(ColorMode.Dark)}
      </ButtonGroup>
    </Box>
  );
};
const PushTokenSettings: FC = () => {

  const push = usePushNotification();
  const appNotification = useAppNotifications();

  const [isIssueListVisible, setIsIssueListVisible] = useState<boolean>(false);

  const handleNotificationClick = useCallback(async () => {
    if (push.isTokenConfigured) {
      const disableResult = await push.requestDisablePush();

      if (disableResult) {
        appNotification.info('Push-Mitteilungen deaktiviert');
      }
      else {
        appNotification.error('Fehler beim deaktivieren von Push-Mitteilungen');
      }

      return;
    }

    const enableResult = await push.requestEnablePush();

    if (enableResult) {
      appNotification.success('Push-Mitteilungen aktiviert');
    }
    else {
      appNotification.error('Fehler beim aktivieren von Push-Mitteilungen');
    }
  }, [push, appNotification]);

  return (
    <Box>
      <Box  sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Typography>Push-Mitteilungen</Typography>
          {push.issues.length > 0 && (
            <IconButton onClick={() => setIsIssueListVisible(!isIssueListVisible)}>
              <ErrorOutlineIcon color="error" />
            </IconButton>
          )}
        </Box>
        <Switch checked={push.isTokenConfigured} onClick={handleNotificationClick} />
      </Box>

      {isIssueListVisible && (
        <Box>
          {push.issues.map((value, index) => (
            <Box key={index} sx={{display: 'flex', alignItems: 'center'}}>

              <ArrowForwardIcon fontSize="small" sx={{mr: 1}} />

              <Typography>{value}</Typography>

              {index < (push.issues.length - 1) && (
                <Divider />
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const AppSettingsPage: FC = () => {

  const { t } = useTranslation();

  return (
    <BasePageWrapper title={t('appSettingsPage.title')} requireLogin={false}>

      <Section title="App-Einstellungen">

        <Card sx={{p: 1}}>

          <Stack spacing={1}>

            <ColorModeSettings />

            <PushTokenSettings />

          </Stack>

        </Card>

      </Section>

    </BasePageWrapper>
  );
};

export default AppSettingsPage;
