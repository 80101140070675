import React, {FC, useEffect, useState} from 'react';
import './App.css';
import MenuBar from "./layout/menu-bar/MenuBar";
import { Backdrop, CircularProgress } from "@mui/material";
import routes from './pages/routes';
import {useSupabaseSession} from "./hooks/utils/useSupabaseSession";
import useAsyncEffect from "./hooks/utils/useAsyncEffect";
import {useCurrentPageTitle, useIsLoading} from "./hooks/filter";
import {useProfileActions} from "./hooks/actions/profile.actions";
import {useSkillActions} from "./hooks/actions";
import {SnackbarProvider} from "notistack";
import {useDeviceActions} from "./hooks/actions/device.actions";
import {useCurrentDevice} from "./hooks/filter/device.filter";

const App: FC = () =>  {

  const skillActions = useSkillActions();
  const profileActions = useProfileActions();
  const deviceActions = useDeviceActions();
  const currentDevice = useCurrentDevice();
  const [devicesFetched, setDevicesFetched] = useState<boolean>(false);

  const session = useSupabaseSession();

  const isLoading = useIsLoading();
  const pageTitle = useCurrentPageTitle();

  const userId = session?.user?.id || null;

  useAsyncEffect(async () => {

    if (userId === null)
      return;

    setDevicesFetched(false)
    await profileActions.fetchUserItems();
    await skillActions.fetchSkills();
    await deviceActions.fetchUserItems();
    setDevicesFetched(true);

  }, [userId]);

  useAsyncEffect(async () => {

    if (!devicesFetched)
      return;

    if (userId === null)
      return;

    if (currentDevice !== null)
      return;

    await deviceActions.createItem({
      userId,
    });
  }, [userId, currentDevice, devicesFetched]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <SnackbarProvider>

        <MenuBar />

        { routes }

      </SnackbarProvider>

      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}>

          <CircularProgress color="inherit" />

        </Backdrop>
      )}
    </>
  );
}

export default App;
