import * as React from 'react';
import {useMemo} from 'react';
import {createRoot} from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';

import './index.css';
import generateTheme from './theme';

import App from './App';
import {HashRouter} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';

import './i18n/i18n';
import {useActualColorMode} from "./hooks/themeHooks";
import * as serviceWorkerRegistration from "./service-worker/serviceWorkerRegistration";

const rootElement = document.getElementById('root');

const AppIndex = () => {

  const colorMode = useActualColorMode();

  const theme = useMemo(() => {
    return generateTheme(colorMode);
  }, [colorMode]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}>

        <CssBaseline />

        <HashRouter>
          <App />
        </HashRouter>

      </LocalizationProvider>

    </ThemeProvider>
  );
}

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    // <React.StrictMode>
    <AppIndex/>
    // </React.StrictMode>
  );
}

serviceWorkerRegistration.register();
