import React, {FC, useCallback, useMemo} from "react";
import {
  Box, Divider,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  SwipeableDrawer
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LoginIcon from '@mui/icons-material/Login';
import {
  PATH_APP_ABOUT,
  PATH_APP_SETTINGS,
  PATH_AUTH,
  PATH_MAP, PATH_MY_EVENTS, PATH_MY_ORGANIZATIONS,
  PATH_ROOT,
  PATH_USER
} from "../../pages/routes";
import AppDrawerListItem from "./AppDrawerListItem";
import {useTranslation} from "react-i18next";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GetAppIcon from '@mui/icons-material/GetApp';

import {useIsMobile} from "../../hooks/themeHooks";
import {useHasSession} from "../../hooks/filter";
import {useProfileOrganizations} from "../../hooks/filter"
import useAppInstaller from "../../hooks/native/useAppInstaller";
import {useAppNotifications} from "../../hooks/utils/useAppNotifications";


interface AppDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}
const AppDrawer: FC<AppDrawerProps> = ({isOpen, onClose, onOpen}) => {

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const hasSession = useHasSession();
  const organizations = useProfileOrganizations();

  const appInstaller = useAppInstaller();
  const appNotifications = useAppNotifications();

  const isBusinessAccount = useMemo<boolean>(() => {
    return organizations.length > 0;
  }, [organizations]);

  const handleOpen = useCallback(() => {
    onOpen?.();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const authorizedItems = useMemo(() => {

    if (!hasSession)
      return null;

    return (
      <>

        <Divider />

        <AppDrawerListItem
          icon={<PersonIcon />}
          requireSession={true}
          title={t('userPage.title')}
          pagePath={PATH_USER}
          onNavigate={handleClose}
        />

      </>
    );
  }, [hasSession, handleClose, t]);

  const businessItems = useMemo(() => {

    if (!isBusinessAccount)
      return null;

    return (
      <>

        <Divider />

        <AppDrawerListItem
          icon={<CorporateFareIcon />}
          requireSession={true}
          title={t('myOrganizationsPage.title')}
          pagePath={PATH_MY_ORGANIZATIONS}
          onNavigate={handleClose}
        />

        <AppDrawerListItem
          icon={<CalendarMonthIcon />}
          requireSession={true}
          title={t('myEventsPage.title')}
          pagePath={PATH_MY_EVENTS}
          onNavigate={handleClose}
        />

      </>
    );
  }, [isBusinessAccount, handleClose, t]);

  const unauthorizedItems = useMemo(() => {

    if (hasSession)
      return null;

    return (
      <>

        <Divider />

        <AppDrawerListItem
          icon={<LoginIcon />}
          title={t('authPage.title')}
          pagePath={PATH_AUTH}
          onNavigate={handleClose}
        />

      </>
    );
  }, [hasSession, handleClose, t]);

  const handleRequestInstallation = useCallback(async () => {
    const result = await appInstaller.requestInstallation();

    if (result) {
      appNotifications.success('Die App wurde erfolgreich installiert!');
    }
    else {
      appNotifications.error('Installation fehlgeschlagen');
    }
  }, [appInstaller, appNotifications]);

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Box sx={ { position: 'relative', height: 120, backgroundColor: 'primary.dark', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: isMobile ? '80vw' : '20vw'}}>
        <img
          src="/assets/logos/logo-white-01.svg"
          style={{ position: 'relative', width: isMobile ? '80vw' : '20vw', height: 'auto'}}
          alt="jobs4you logo"
        />
      </Box>

      <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

        <List sx={{pt:0}}>

          <AppDrawerListItem
            icon={<PlayArrowIcon />}
            requireSession={false}
            title={t('homePage.title')}
            pagePath={PATH_ROOT}
            onNavigate={handleClose}
          />

          {/*<AppDrawerListItem*/}
          {/*  icon={<ListIcon />}*/}
          {/*  requireSession={false}*/}
          {/*  title={t('jobListingsPage.title')}*/}
          {/*  pagePath={PATH_JOB_LISTINGS}*/}
          {/*/>*/}

          <AppDrawerListItem
            icon={<PinDropIcon />}
            requireSession={true}
            title="Karte"
            pagePath={PATH_MAP}
            onNavigate={handleClose}
          />

          {authorizedItems}

          {businessItems}

          {unauthorizedItems}

        </List>

        <List sx={{pb:0}}>

          {appInstaller.canInstall && !appInstaller.isInstalled && (
            <ListItem disablePadding onClick={handleRequestInstallation}>
              <ListItemButton>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={"Jetzt installieren!"} />
              </ListItemButton>
            </ListItem>
          )}

          <Divider />

          <AppDrawerListItem
            icon={<SettingsIcon />}
            title={t('appSettingsPage.title')}
            pagePath={PATH_APP_SETTINGS}
            onNavigate={handleClose}
          />

          <AppDrawerListItem
            icon={<InfoIcon />}
            title={t('aboutAppPage.title')}
            pagePath={PATH_APP_ABOUT}
            onNavigate={handleClose}
          />

        </List>

      </Box>

    </SwipeableDrawer>
  );
};

export default AppDrawer;
